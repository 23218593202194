<template>
  <div style="height:100%">
    <v-col style="height:100%">
      <div class="d-flex justify-left align-left mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:external-filter>
            <SuppliersAutocomplete
              class="pb-5"
              v-model="externalFilter.value"
              label="Inserire Fornitore"
              :return-object="false"
              append-icon="mdi-domain"
              :auto-detect-new-supplier="false"
              dense
              outlined
              :filled="false"
              :style="{ 'min-width': '30%' }"
              :disabled="loading"
            >
            </SuppliersAutocomplete>
          </template>

          <template v-slot:custom-status="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-switch
                  v-model="filter.value"
                  false-value="pending"
                  true-value="completed"
                  color="red"
                  :label="filter.value == 'pending' ? 'In corso' : 'Completato'"
                  :class="filter.value == 'pending' ? 'custom-red-switch' : 'custom-green-switch'"
                  flat
                >
                </v-switch>
              </v-col>
            </v-row>
          </template>
        </AdvancedFilter>
      </div>
      <v-row style="height:100%;overflow: hidden" class="ma-5 d-flex justify-center">
        <TypeDataTable
          style="width:100%; height:78%"
          v-model="selected"
          loading-text="Caricamento inventari..."
          :headers="headers"
          :loading="loading"
          :items="items"
          :show-actions="false"
          :show-select="false"
          noDataText="Nessun inventario trovato"
          :rowPerPageOptions="[200, 100, 50, 20]"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          @input="rowClicked($event)"
        >
          <template v-slot:custom-supplier="{ item }">
            <v-chip v-if="!!item.supplier">
              {{ item.supplier.businessName }}
            </v-chip>
          </template>

          <template v-slot:custom-products="{ item }">
            <v-chip v-if="!!item.movement">
              {{ item.movement.movementItems.length }}
            </v-chip>
          </template>

          <template v-slot:custom-status="{ item }">
            <v-chip v-if="!!item.movement" :color="item.status == 'completed' ? 'success' : 'error'">
              {{ item.status == 'completed' ? 'Completato' : 'In corso' }}
            </v-chip>
          </template>
        </TypeDataTable>
      </v-row>
    </v-col>
    <StandardDialog
      v-model="showAdjustmentsDialog"
      :title="dialogTitle"
      dialog-height="800px"
      dialog-max-width="1400px"
    >
      <template v-slot:header-actions="{ on, attrs }">
        <v-btn 
          v-if="!!rowSelected && rowSelected.status != 'completed'"
          text 
          link 
          @click="editAdjustment"
          :disabled="isLicenseBlocked"
        >
          <v-icon class="mr-2">mdi-dolly</v-icon> Riprendi Inventario
        </v-btn>
        <ExportExcelTable
          v-if="!!rowSelected && rowSelected.status == 'completed'"
          :worksheetName="worksheetName"
          :fileName="fileName"
          :fieldsToExport="fieldsToExport"
          :fetch="fetchExcel"
        > </ExportExcelTable>
      </template>
      <TypeDataTable
        v-if="!!rowSelected"
        style="width:100%; height:87%"
        loading-text="Caricamento inventari..."
        :headers="headersProducts"
        :items="rowSelected.movement.movementItems"
        :show-actions="false"
        :show-select="false"
        noDataText="Nessun inventario trovato"
        disable-pagination
      >
        <template v-slot:custom-code="{ item }">
          {{ item.item.code }}
        </template>

        <template v-slot:custom-description="{ item }">
          {{ item.item.description }}
        </template>

        <template v-slot:custom-adjustmentQuantity="{ item }">
          <v-chip
            :color="item.type == 'inventoryUnload' ? 'error' : 'success'"
          >
            {{ item.type != 'inventoryUnload' ? '+' + item.qty : '-' + item.qty }}
          </v-chip>
        </template>

        <template v-slot:custom-priceNetPurchase="{ item }">
          {{ Number(item.item.priceNetPurchase).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}
        </template>

        <template v-slot:custom-tags="{ item }">
          <v-chip v-if="!!item.item.tags">
            {{ item.item.tags[0].description }}
          </v-chip>
        </template>

        <template v-slot:custom-supplier="{ item }">
          {{ item.item.supplier.businessName }}
        </template>

        <template v-slot:custom-newQty="{ item }">
          {{ Number(item.oldStoredQty) + (item.type == 'inventoryUpload' ? Number(item.qty) : -Number(item.qty)) }}
        </template>

        <template v-slot:externalFooter>
          <TypeDataTable
            :headers="totalHeader"
            :items="totalRow"
            :show-select="false"
            :show-actions="false"
            disablePagination
          >
          </TypeDataTable>
        </template>
      </TypeDataTable>
    </StandardDialog>
  </div>
</template>
<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import MovementsAdjustmentsService from "@/services/warehouse/movementAdjustments.service.js";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import dateUtils from "@/mixins/common/dateUtils"
import Vue from "vue";

export default {
  name: "NewMovementAdjustments",
  components: {
    TypeDataTable,
    AdvancedFilter,
    StandardDialog,
    SuppliersAutocomplete,
    ExportExcelTable
  },
  props: {
    bus: {
      type: Object,
      default: () => new Vue()
    }
  },
  data: function () {
    return {
      supplierSelected: undefined,
      loading: false,
      headers: [
        { text: 'Data Creazione', value: 'createdAt', type: 'datetime' },
        { text: 'Data Ultima Modifica', value: 'updatedAt', type: 'datetime' },
        { text: 'Fornitore', value: 'supplier', type: 'custom' },
        { text: 'Prodotti Modificati', value: 'products', type: 'custom' },
        { text: 'Stato', value: 'status', type: 'custom' },
      ],
      headersProducts: [
        { text: 'Nome Prodotto', value: 'description', type: 'custom' },
        { text: 'Barcode', value: 'code', type: 'custom' },
        { text: 'Fornitore', value: 'supplier', type: 'custom' },
        { text: "Prezzo netto d'acquisto", value: 'priceNetPurchase', type: 'custom' },
        { text: 'Famiglia', value: 'tags', type: 'custom' },
        { text: 'Giacenza Precedente', value: 'oldStoredQty' },
        { text: 'Giacenza Rilevata', value: 'newQty', type: 'custom' },
        { text: 'Quantità Modificata', value: 'adjustmentQuantity', type: 'custom' },
      ],
      totalHeader: [
        { text: 'N° Prodotti', value: 'nItem' },
        { text: 'Fornitore', value: 'supplier' },
        { text: 'Totale', value: 'total', type: 'price' },
      ],
      totalRow: [
        { nItem: 0, supplier: '', total: 0 }
      ],
      items: [
      ],
      selected: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      rowSelected: undefined,
      showAdjustmentsDialog: false,
      dialogTitle: 'Dettaglio inventario',
      filterTypes: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data Creazione', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'date', operator: 'between', field: 'updatedAt', name: 'Data Ultima Modifica', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'equal', field: 'status', name: 'Stato', label: '', color: '', value: undefined, icon: 'mdi-timer-edit' },
        { type: 'number', operator: 'equal', field: 'movementItems', name: 'Prodotti Modificati', label: '', color: '', value: undefined }
      ],
      advanceFilters: [
      ],
      externalFilter: { type: 'custom', operator: 'equal', field: 'supplierId', name: 'Fornitore', label: 'Fornitore', color: "", value: undefined },
      filtersValue: [
      ],
      worksheetName: "",
      fileName: "",
      fieldsToExport: {},
      isLicenseBlocked: undefined
    };
  },
  mixins:[dateUtils],
  mounted: function () {
    this.fetchAdjustments();
    this.bus.$on('refresh-adjustments', () => {
      this.fetchAdjustments();
    });
  },
  beforeDestroy: function () {
  },
  methods: {
    async fetchAdjustments() {
      this.loading = true;

      let filters = [...this.filtersValue]
      if(!!this.externalFilter.value)
        filters.push(this.externalFilter)

      MovementsAdjustmentsService.list(0, 100, filters).then(results => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page && this.totalPages != 0) {
          this.page = this.totalPages;
        }
        
        this.items = results.rows
        this.loading = false;        
      })
    },
    rowClicked(row) {
      if (!!row[0]) {
        this.selected = [];
        this.rowSelected = row[0];
        this.dialogTitle = 'Dettaglio inventario del ' + new Date(this.rowSelected.createdAt).toLocaleDateString();

        let total = 0;
        for(let item of this.rowSelected.movement.movementItems){
          let qty = Number(item.oldStoredQty) + (item.type == 'inventoryUpload' ? Number(item.qty) : -Number(item.qty))
          total += qty * Number(item.item.priceNetPurchase);
        }

        this.totalRow = [
          { nItem: this.rowSelected.movement.movementItems.length, supplier: this.rowSelected.supplier.businessName, total: total }
        ];

        this.fieldsToExport = MovementsAdjustmentsService._fieldsToExportSingleAdjustment();
        this.worksheetName = "Export Inventario ";
        this.fileName =
          "Inventario_" + this._buildDateToExport(new Date(this.rowSelected.createdAt)) +
          ".xls";

        this.showAdjustmentsDialog = true;
      }
    },
    fetchExcel() {
      let data = [...this.rowSelected.movement.movementItems]
      data.push({
        item: {
          description: this.totalRow[0].nItem, 
          priceNetPurchase: this.totalRow[0].total, 
          supplier: { 
            businessName: this.totalRow[0].supplier 
          } 
        }
      })

      return data;
    },
    editAdjustment(){
      this.showAdjustmentsDialog = false;
      this.bus.$emit('openEditMovementAdjustments', this.rowSelected.id);
    },
    applyFilters(filters) {
      this.fetchAdjustments();
    },
  },
  computed: {
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>

.custom-green-switch .v-input--selection-controls__input div {
  color: green !important;
  caret-color: green !important;
}
.custom-red-switch .v-input--selection-controls__input div {
  color: red !important;
  caret-color: red !important;
}

</style>