<template>
  <div style="height:100%">
    <v-col style="height:100%">
      <v-row class="d-flex justify-center" style="height:10%">
        <div class="ma-5" style="min-width:8%">
        </div>
        <SuppliersAutocomplete
          class="ma-5"
          v-model="supplierSelected"
          label="Inserire Fornitore"
          return-object
          @input="handleSupplierChange"
          append-icon="mdi-domain"
          :auto-detect-new-supplier="false"
          dense
          outlined
          :filled="false"
          :style="{ 'min-width': '25%' }"
          :disabled="loading"
          :external-filters="supplierFilters"
        >
        </SuppliersAutocomplete>
        <v-btn 
          class="ma-5" 
          style="min-width:8%;background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));" 
          color="white" 
          dark
          @click="openAlertDialog"
          :disabled="!this.supplierSelected"
          :loading="loading"
        >
          Salva
        </v-btn>
      </v-row>
      <v-row style="height:100%;overflow-y: auto;" class="pa-5 d-flex justify-center">
        <TypeDataTable
          style="width:90%; height:85%"
          v-model="selected"
          loading-text="Caricamento prodotti..."
          :headers="headers"
          :loading="loading"
          :items="items"
          disable-pagination
          :select-on-row-click="false"
          :show-actions="false"
          :show-select="false"
          :noDataText="!!supplierSelected ? 'Nessun Prodotto Trovato' : 'Selezionare un Fornitore'"
          :search="search"
          :custom-filter="nameOrBarcodeFilter"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Nome Prodotto o Barcode"
              dense
              outlined
              :disabled="!supplierSelected"
            ></v-text-field>
          </template>
          <template v-slot:custom-actualQuantity="{ item }">
            <v-text-field min="0" max="999999" type="number" v-model="item.actualQuantity" @input="graterThan0(item)"></v-text-field>
          </template>

          <template v-slot:custom-tags="{ item }">
            <v-chip v-if="!!item.tags">
              {{ item.tags[0].description }}
            </v-chip>
          </template>

          <template v-slot:custom-qtyInStock="{ item }">
            <v-chip v-if="item['qtyInStock'] <= 0" color="error">
              {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
            >

            <v-chip
              v-else-if="
                Number(item['qtyInStock']) < Number(item['qtyMinStock'])
              "
              color="orange"
            >
              {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
            >
            <v-chip v-else-if="item['qtyInStock'] > 0" color="success">
              {{ item["qtyInStock"] }}</v-chip
            >
          </template>

          <template v-slot:custom-supplier="{ item }">
            {{ item.supplier.businessName }}
          </template>
        </TypeDataTable>
      </v-row>
    </v-col>
    <StandardDialog
      v-model="alertDialog"
      title="Attenzione"
      :dialog-height="null"
      dialog-width="500px"
    >
      <div>
        Attenzione! Sei sicuro di voler modificare {{getNumberOfProduct()}} prodotti?
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="() => { alertDialog = false }"
        >
          Annulla
        </v-btn>
        <v-btn
          color="default"
          text
          @click="saveAdjustments"
        >
          Salva
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>
<script>
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import MovementsAdjustmentsService from "@/services/warehouse/movementAdjustments.service.js";
import StandardDialog from '@/components/common/StandardDialog.vue';
import Vue from "vue";

export default {
  name: "NewMovementAdjustments",
  components: {
    SuppliersAutocomplete,
    TypeDataTable,
    StandardDialog
  },
  props: {
    bus: {
      type: Object,
      default: () => new Vue()
    }
  },
  data: function () {
    return {
      supplierSelected: undefined,
      loading: false,
      headers: [
        { text: 'Nome Prodotto', value: 'description' },
        { text: 'Barcode', value: 'code' },
        { text: 'Fornitore', value: 'supplier', type: 'custom' },
        { 
          text: "Prezzo netto d'acquisto", 
          value: 'priceNetPurchase', 
          type: 'price',
          filter: (value, search, item) => {
            if(!this.price) return true;
            return item.priceNetPurchase >= this.price;
          }
        },
        { text: 'Famiglia', value: 'tags', type: 'custom' },
        { 
          text: 'Giacenza Attuale', 
          value: 'qtyInStock', 
          type: 'custom',
          filter: (value, search, item) => {
            if(!this.quantity) return true;
            return item.qtyInStock >= this.quantity;
          }
        },
        { text: 'Giacenza Rilevata', value: 'actualQuantity', type: 'custom' },
      ],
      items: [],
      selected: [],
      loading: false,
      alertDialog: false,
      search: '',
      price: undefined,
      quantity: undefined,
      supplierFilters: {
        withoutOngoingAdjustments: true
      }
      //8016818084
    };
  },
  mounted: function () {
    
  },
  beforeDestroy: function () {
  },
  methods: {
    async handleSupplierChange() {
      this.loading = true;
      if(this.supplierSelected) {
        this.items = await MovementsAdjustmentsService.getProductsBySupplier(this.supplierSelected.id);
      } else {
        this.items = [];
      }
      this.loading = false;
    },
    graterThan0(selected) {
      if (Number(selected.actualQuantity) < 0)
        selected.actualQuantity = 0
      else if (Number(selected.actualQuantity) > 999999) 
        selected.actualQuantity = 999999
    },
    openAlertDialog() {
      this.alertDialog = true;
    },
    saveAdjustments(){
      this.alertDialog = false
      this.loading = true;
      let items = this.items.map(item => {
        return {
          id: item.id,
          actualQuantity: item.actualQuantity,
          qtyInStock: item.qtyInStock
        }
      }).filter(item => item.actualQuantity != undefined && item.actualQuantity != null)

      MovementsAdjustmentsService.create({items: items, supplierId: this.supplierSelected.id}).then(async (res) => {
        this.supplierSelected = undefined;
        this.bus.$emit('refresh-adjustments')
        this.bus.$emit('go-to-adjustments-list')
        this.$delegates.snackbar('Inventario avvenuto con successo')
        this.loading = false;
      });
    },
    getNumberOfProduct() {
      return this.items.filter(item => item.actualQuantity != undefined && item.actualQuantity != null).length;
    },
    nameOrBarcodeFilter (value, search, item) {
      return item.description.toLowerCase().includes(search.toLowerCase()) || item.code == search;
    },
  },
  computed: {
  },
};
</script>

<style>
</style>