import axios from "@/services/axios";
import UrlKeeper from "@/services/UrlKeeper.js";
import DateHandler from "@/services/common/dateHandler";

class orderManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/movementAdjustments/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  create(movementAdjustments) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/movementAdjustments/create", movementAdjustments).then((response) => {
        resolve(response.results);
      });
    });
  }

  get(movementAdjustmentsId) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/movementAdjustments/get", { id: movementAdjustmentsId }).then((response) => {
        resolve(response.results);
      });
    });
  }

  getProductsBySupplier(supplierId) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/movementAdjustments/getProductsBySupplier", {
        supplierId: supplierId,
      }).then((response) => {
        if (response.success) {
          resolve(response.results);
        }
      });
    });
  }

  addItems(adjustment, items) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/movementAdjustments/addItems", {
        adjustment: adjustment,
        items: items,
      }).then((response) => {
        resolve(response.results);
      });
    });
  }

  _fieldsToExport() {
    return {
      'Data': {
        field: "createdAt",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`, true);
        },
      },
      'Fornitore': {
        field: "supplier",
        callback: (value) => {
          return value.businessName
        },
      },
      'Prodotti Modificati': {
        field: "movement",
        callback: (value) => {
          if (value.movementItems)
            return value.movementItems.map(item => {
              return item.item.code + ' | ' + item.item.description + ' | ' + (item.type == 'inventoryUpload' ? '+' + item.qty : '-' + item.qty)
            }).join(', ')
          else
            return ''
        }
      },
    }
  }
  _fieldsToExportSingleAdjustment() {
    return {
      'Nome Prodotto': {
        field: "item",
        callback: (value) => {
          return value.description
        }
      },
      'Barcode': {
        field: "item",
        callback: (value) => {
          return value.code
        }
      },
      'Fornitore': {
        field: "item",
        callback: (value) => {
          return value.supplier.businessName
        }
      },
      "Prezzo netto d'acquisto": {
        field: "item",
        callback: (value) => {
          return Number(value.priceNetPurchase).toFixed(2);
        }
      },
      'Giaceza Precedente': {
        field: "oldStoredQty",
      },
      'Giacenza Rilevata': {
        callback: (value) => {
          return Number(value.oldStoredQty) + (value.type == 'inventoryUpload' ? Number(value.qty) : -Number(value.qty))
        }
      },
      'Quantità Modificata': {
        callback: (value) => {
          return (value.type == 'inventoryUpload' ? Number(value.qty) : -Number(value.qty))
        }
      },
    }
  }
}

export default new orderManagment();
